<template>
<Banner :imgArr="state.arr" v-if="state.arr!=''" />
    <div class="section">
        <div class="title">
            <h2>{{state.name}}</h2>
            <h3><span>{{formatDate1(state.time)}}</span>|<span>查看：25896</span></h3>
        </div>
        <div class="info" v-html="state.info"></div>
    </div>
</template>
<style  lang="scss" scoped>
.section{
    margin: 1rem auto;
    width: 12rem;
    .title{
        text-align: center;
        h2{
            font-size: .2rem;
            line-height: .4rem;
        }
        h3{
            font-size: .13rem;
            color: #999;
            margin: .1rem 0 .3rem;
            font-weight: normal;
            span{
                padding: 0 .1rem;
            }
        }
    }
    .info{
        // text-align: center;
        font-size: .14rem;
        img{
            display: block;
            margin: 0 auto;
        }
        p{
            text-align: left;
        }
    }
}
</style>
<script>
import {onMounted,reactive} from "vue";
import { useRoute } from 'vue-router'
import {WOW} from 'wowjs' 
import 'animate.css'
import Banner from "@/components/banner1.vue"
import { getProductsDetail,getbannerData} from "../../api/request"
import { formatDate } from '@/assets/utils/utils';
export default {
    components: {
        Banner,
    },
    setup(){
        const arr = [
            {img: require("@/assets/images/banner/banner1.png"),},
            {img: require("@/assets/images/banner/banner2.png"),},
            {img: require("@/assets/images/banner/banner3.png"),},
        ];
        let state = reactive({
            name:"",
            id:"",
            time:"",
            info:"",
            arr
        })
        onMounted(()=>{
            var wow = new WOW({
            boxClass: 'wow',    //需要执行动画元素的Class
            animateClass: 'animated',    //animation.css动画的Class
            offset: 100,    //距离可视区域多少开始执行动画
            mobile: true,    //是否在移动设备执行动画
            live: true    //异步加载的内容是否有效
            })
            wow.init()
            const route = useRoute()
            state.name = route.query.name
            state.id =  route.query.id
            getData(state.id)
        // console.log(route.params)
        })
        function getData(id){
            getbannerData({id:34}).then((res)=>{
                if(res.code == 200){
                state.arr = res.data
                }
            })
            getProductsDetail({id:id}).then((res) => {
                // console.log(res)
                if(res.code == 200){
                    state.info = res.data.content.replace(/<img/g,"<img style='max-width:100%;height:auto;margin:.1rem auto;display: block;'");
                    state.time = res.data.create_time
                    // console.log(state.info)
                }
            })
        }
        //时间

       function formatDate1(time) {
            time = time * 1000
            let date = new Date(time)
            return formatDate(date, 'yyyy-MM-dd')
        }
        return{
            state,
            arr,
            getData,
            formatDate1
        }
         
    }
}
</script>